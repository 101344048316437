<template>
  <div class="c-providerCollapse">
    <div
      class="c-providerCollapse_text"
      v-text="displayFreeText"
    ></div>
    <div
      class="c-providerCollapse_readMore"
      v-if="freeTextNeedReadMore && !freeTextExpanded"
    >
      <a @click="freeTextExpanded = true">
        {{ $t('components.provide_collapse.see_all') }}
        <coconala-icon
          name="chevron-down"
          size="11px"
        />
      </a>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ProviderCollapse',
  props: {
    freeText: {
      type: String,
      defalut: ''
    },
    expansion: {
      type: Boolean,
      default: false
    },
    limitCount: {
      type: Number,
      default: 90
    },
    limitRows: {
      type: Number,
      default: 5
    }
  },
  data() {
    return {
      freeTextExpanded: false
    }
  },
  computed: {
    freeTextNeedReadMore() {
      if (!this.freeText) {
        return false
      }

      if (this.freeText.length > this.limitCount) {
        return true
      }

      if (this.freeText.split('\n').length > this.limitRows) {
        return true
      }

      return false
    },
    displayFreeText() {
      if (!this.freeTextNeedReadMore || this.freeTextExpanded) {
        return this.freeText
      }

      const slicedFreeText = this.freeText.split('\n').slice(0, this.limitRows).join('\n')

      return this.$util.truncate(slicedFreeText, this.limitCount)
    }
  }
}
</script>
<style lang="scss" scoped>
.c-providerCollapse {
  &_text {
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  &_readMore {
    margin-top: 4px;
    margin-bottom: 8px;
    color: $ds2-color-brand-secondary-700;
    font-size: 13px;
    position: relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: -24px;
      width: 100%;
      height: 20px;
      background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
    }
  }
}

@media (max-width: breakpoint(ServiceDetail, M)) {
  .c-providerCollapse {
    &_readMore {
      padding: 12px 0;
      text-align: center;
      font-size: 12px;
    }
  }
}
</style>
